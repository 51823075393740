.custom-modal {
  position: relative;
  width: 50%;
  max-width: 600px;
  margin: auto;
  background-color: var(--card-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-btn:hover {
  color: #000;
}

.modal-body {
  padding: 10px 0;
}

.fees-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.fee-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  width: 120px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fee-installments {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.fee-percentage {
  font-size: 16px;
  color: #555;
}
