/* Container geral do input */
.custom-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  width: 100%;
}

/* Label do input */
.custom-input-label {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.custom-input-icon {
  position: absolute;
  right: 10px;
  top: 60%;
  transform: translateY(10%);
  cursor: pointer;
  font-size: 1.2rem; /* Ajuste o tamanho conforme necessário */
  color: black; /* Cor do ícone */
  background-color: transparent !important;
}

/* Campo do input */
.custom-input-field {
  font-family: Montserrat, sans-serif;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
}

/* Estilo quando houver erro */
.custom-input-field.input-error {
  border-color: #68e0cf;
  /* Vermelho */
}

.custom-input-field:focus {
  border-color: #68e0cf;
  /* Azul */
  outline: none;
}

/* Mensagem de erro */
.error-message {
  color: #e74c3c;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.required {
  color: #e74c3c;
}
