.custom-dialog {
  border-radius: 50px;
}

.custom-dialog .p-dialog-header {
  background: #000;
  color: white;
  font-weight: bold;
  padding: 1rem;
}

.custom-dialog .p-dialog-content {
  font-size: 1.1em;
  color: #000;
  padding: 1rem;
}

.custom-dialog .p-dialog-footer {
  display: none;
}
