/* Estilos para o TaxCard, prefixado com .tax-card para evitar conflitos */

.tax-card {
  background-color: var(--card-color);
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  position: relative;
  max-width: 100%;
  margin: 5px;
}

.tax-card:hover {
  border: 2px solid var(--inverted-card-color);
}

.tax-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tax-card-title {
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  color: var(--text-color);
}

.tax-card-icon svg {
  width: 60px;
  height: 60px;
  fill: var(--text-color);
}

.tax-card-description {
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  color: var(--text-color);
  font-size: 16px;
}

.tax-card-rate {
  font-size: 24px;
  color: var(--text-color);
  margin-top: 10px;
}

.tax-card-reserve {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: var(--secondary-color);
  margin-top: 5px;
}

.tax-card-accordion {
  margin-top: 20px;
}

.tax-card-accordion .accordion-toggle {
  background-color: var(--background-third-color);
  color: var(--secondary-text-color);
  border: none;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
}

.tax-card-accordion .accordion-content {
  background-color: var(--background-third-color);
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

.tax-card-accordion .accordion-content ul {
  list-style-type: none;
  padding: 0;
}

.tax-card-accordion .accordion-content ul li {
  color: var(--secondary-text-color);
  padding: 5px 0;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .tax-card {
    max-width: 100%;
    padding: 15px;
  }

  .tax-card-title {
    font-size: 18px;
  }

  .tax-card-rate {
    font-size: 22px;
  }

  .tax-card-accordion .accordion-toggle {
    font-size: 14px;
    padding: 8px;
  }

  .tax-card-accordion .accordion-content {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .tax-card {
    padding: 10px;
  }

  .tax-card-title {
    font-size: 16px;
  }

  .tax-card-rate {
    font-size: 20px;
  }

  .tax-card-accordion .accordion-toggle {
    font-size: 13px;
    padding: 6px;
  }

  .tax-card-accordion .accordion-content ul li {
    padding: 3px 0;
  }
}
