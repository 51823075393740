.dash-container {
  display: flex;
  justify-content: space-between;
}

.dash-content-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 15px;
}

.login-button {
  width: 300px;
  margin-left: 20px;
}


@media (max-width: 900px) {
  .dash-container {
    flex-direction: column;
  }
}

@media (max-width: 1750px) {
  .merchant-grid-1 {
    width: 100%;
  }

  .merchant-grid-2 {
    min-width: 100%;
    justify-content: space-between;
    flex-direction: column;
  }

  .sales-statistics {
    width: 100%;
  }

  .product-table {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .merchant-grid-2 {
    flex-direction: column;
    width: 100%;
  }

  .sales-statistics {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 600px) {
  .sales-overview-card {
    flex: 1 1 100%;
  }

  .login-button {
    margin-top: 10px;
  }

  .doughnut-charts,
  .line-chart {
    flex: 1 1 100%;
    margin-top: 20px;
  }
}
