.login-page {
  display: flex;
  height: 100vh;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.lottie-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page.hidden {
  opacity: 0;
}

.login-container {
  background-image: url(/src/assets/background-login2.png);
  background-color: #000;
  background-position: center;
  background-size: cover;
}

.art-section {
  width: 100vw;
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.art-image {
  max-width: 100%;
  max-height: 100vh;
  height: auto;
  object-fit: contain;
  position: relative;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.custom-input {
  margin-bottom: 1rem;
  width: 100%;
}

.custom-input input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.remember-me {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #505050;
}

.remember-me input {
  margin-right: 0.5rem;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: #b0b0b0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--border-color);
  margin: 0 1rem;
}

.divider span {
  color: #707070;
  font-size: 0.9rem;
  font-weight: 500;
}

.error-message {
  color: red;
  margin-top: 1rem;
  font-size: 0.9rem;
}

@media screen and (max-width: 768px) {
  .login-page {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .art-section {
    display: none; /* Esconde a seção de arte no mobile */
  }
}
