.payment-method-container {
  display: flex;
  gap: 20px;
}

.payment-method-section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  background-color: var(--card-color);
}

.payment-method-section h2 {
  font-size: 20px;
  color: var(--text-color);
  margin-bottom: 15px;
  text-align: center;
}

.acquirer-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.acquirer-row:last-child {
  border-bottom: none;
}

.acquirer-row span {
  font-size: 16px;
  color: var(--text-color);
  flex: 1;
}

.percentage-input {
  width: 80px;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  margin-left: 10px;
  background-color: #fefefe;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s;
}

.percentage-input:focus {
  border-color: #007bff;
  outline: none;
}

.remaining-percentage {
  font-size: 14px;
  color: #888;
  margin-top: 10px;
  text-align: right;
  font-style: italic;
}

.payment-method-section:hover {
  border-color: #000;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.1);
  transition: all 0.3s ease;
}