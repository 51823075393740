.info-icon {
  margin-left: 5px;
  font-size: 14px;
  color: var(--secondary-text-color);
}

.dropdown button {
  font-family: "Poppins", sans-serif;
  background-color: var(--background-color);
  border: none;
  border: solid 1px #c9c9c9;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.product-selection {
  display: flex;
}

.product-button {
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #666;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-button.selected {
  background-color: #68e0cf;
  color: #fff;
}

.product-button .info-icon {
  margin-left: 5px;
  font-size: 12px;
  color: #fff;
}

.method-selection {
  display: flex;
}
