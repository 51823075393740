.dash-information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 2rem;
}

.dash-information-2 {
  flex-direction: column;
}

.dash-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 2rem;
  letter-spacing: -0.07rem;
}

.dash-title-home {
  margin: 0;
}

.dash-info {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: var(--thirty-text-color);
  margin: 0 0 10px 0;
}

.dash-admin-container {
  display: flex;
  justify-content: space-between;
}

.admin-content-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.admin-grid-1 {
  width: 80%;
}

.admin-grid-2 {
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: center;
}

.overview-cards {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* background-color: var(--background-color); */
  border-radius: 20px;
  /* box-shadow: var(--box-shadow-color) 0px 4px 12px; */
  /* padding: 20px; */
}

.overview-cards-1, .charts-section {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* background-color: var(--background-color); */
  border-radius: 20px;
  /* box-shadow: var(--box-shadow-color) 0px 4px 12px; */
  /* padding: 20px; */
}

.admin-grid-2 {
  display: flex;
}

.admin-content-1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sales-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  gap: 20px;
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: var(--box-shadow-color) 0px 4px 12px;
  padding: 20px;
}

.table-section {
  margin: 10px;
  gap: 20px;
  background-color: var(--card-color);
  border-radius: 20px;
  padding: 20px;
}

.line-charts {
  flex: 2;
  max-width: 100%;
  background-color: var(--background-color);
  border-radius: 20px;
  box-shadow: var(--box-shadow-color) 0px 4px 12px;
  /* margin-right: 20px; */
  /* margin-bottom: 20px; */
}

@media (max-width: 1750px) {
  .charts-section {
    flex-direction: column;
  }

  .admin-grid-1 {
    width: 100%;
  }

  .admin-grid-2 {
    min-width: 100%;
    justify-content: space-between;
  }

  .sales-statistics {
    width: 100%;
  }

  .line-charts {
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
  }

  .doughnut-charts {
    flex-direction: row;
    margin-top: 20px;
    max-width: 100%;
  }
}

@media (max-width: 900px) {
  .sales-overview-card {
    flex: 1 1 calc(50% - 20px);
  }

  .dash-admin-container {
    flex-direction: row;
  }
}

@media (max-width: 1000px) {
  .admin-grid-2 {
    flex-direction: column;
    width: 100%;
  }

  .sales-statistics {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 600px) {
  .sales-overview-card {
    flex: 1 1 100%;
  }

  .doughnut-charts,
  .line-chart {
    flex: 1 1 100%;
    margin-top: 20px;
  }
}
