.tax-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Espaço entre os itens */
}

/* Cada item vai ocupar 50% da largura em telas maiores */
.tax-item {
  flex: 1 1 calc(50% - 10px); /* Ocupa 50% da largura menos 10px para espaçamento */
}

/* Em telas menores (como tablets e smartphones), ajustamos para ocupar 100% */
@media (max-width: 768px) {
  .tax-item {
    flex: 1 1 100%; /* Ocupa 100% da largura em telas menores */
  }
}
