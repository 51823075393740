/* Estilos do overlay do modal */
.custom-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Estilos da caixa modal */
.custom-modal {
  background-color: var(--card-color);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Cabeçalho do modal */
.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header h2 {
  font-family: "Poppins", sans-serif;
  color: var(--text-color);
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* Seletor de Pix/Boleto e Cartão de Crédito */
.select-option {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

/* Sobrescrevendo o estilo p-button para SelectButton */
.select-option .p-button {
  background: #f5f5f5 !important;
  border: 1px solid #ccc !important;
  color: var(--secondary-text-color) !important;
  box-shadow: none !important;
}

.saque-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saque-section p {
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
}

/* Seção de saque */
.saque-section input {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Rodapé do modal */
.modal-footer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.sacar-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.sacar-btn:hover {
  background-color: #fff;
  color: #000;
}

.cancel-btn {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #000;
  color: #fff;
}
