.tax-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.tax-column {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 60%;
  align-items: center;
}