.date-picker-container {
  position: relative;
}

.selected-range {
  font-size: 1rem;
  font-weight: 400;
  padding: 10px;
  border: 1px solid var(--third-text-color);
  cursor: pointer;
  border-radius: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 40px;
  background-color: var(--card-color);
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  z-index: 1;
}

.dropdown-item {
  font-family: 'Poppins', sans-serif;
  font-size: .9rem;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: var(--text-color);
  color: var(--card-color)
}

.calendar-container {
  position: absolute;
  top: 40px;
  left: -500px;
  background-color: var(--card-color);
  border: 1px solid #ccc;
  z-index: 1;
  padding: 10px;
}


.rdp-range_middle, .rdp-range_end .rdp-day_button, .rdp-range_start .rdp-day_button {
  background-color: var(--text-color);
  color: var(--card-color);
  border: transparent;
}

.rdp-range_start {
  background: var(--text-color);
}

.rdp-range_end {
  background: var(--text-color);
}

.calendar-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.clear-button {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #000;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #000;
  color: #fff;
}

.apply-button {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 0.5rem 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.apply-button:hover {
  background-color: #fff;
  color: #000;
}

.rdp-months {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: var(--rdp-months-gap);
  max-width: fit-content;
}
