.custom-filter {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.filter-dropdown,
.filter-input {
  padding: 0.5rem 0.8rem;
  margin-right: 1rem;
  border: 1px solid #ced4da;
  border-radius: 25px;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: var(--background-color);
}

.filter-dropdown .p-dropdown {
  width: 100%;
}

.filter-input {
  width: 100%;
}

.filter-dropdown .p-dropdown-label {
  padding-left: 0.8rem;
  font-size: 1rem;
  color: #888;
}

.filter-dropdown .p-dropdown-trigger {
  border-radius: 0 25px 25px 0;
}

.filter-dropdown select:focus,
.filter-input:focus {
  outline: none;
  border-color: #80bdff;
}

.custom-filter-wrapper {
  display: flex;
  align-items: center;
  padding: 0.3rem 1rem;
  border: 1px solid #ced4da;
  border-radius: 25px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.custom-filter .p-dropdown {
  width: auto;
  padding: 0;
}

.custom-filter .p-inputtext {
  width: auto;
  padding: 0.5rem 0.8rem;
}
