.content-button {
  border: 1px solid #000;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 16px;
  margin: 0 5px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.content-button:hover {
  opacity: 0.5;
}

.button-icon {
  margin-right: 8px;
}
