/* Container principal da sidebar */
.sidebars-container {
  display: flex;
  background: var(--third-text-color);
  margin: 14px;
  border-radius: 18px;
  transition: width 0.3s ease;
}

/* Sidebar principal */
.first-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 70px;
  padding: 20px 0;
  transition: width 0.3s ease;
}

/* Sidebar expandida */
.first-sidebar.expanded {
  width: 200px; /* Largura expandida */
}

.icon-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Lista de ícones */
.icon-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.icon-list li {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  margin: 10px;
  border-radius: 12px; /* Fundo arredondado */
  transition: background-color 0.3s ease, transform 0.2s;
  cursor: pointer;
  position: relative; /* Necessário para barra lateral */
}

.icon-list li i {
  font-size: 18px;
  color: var(--icons-sidebar);
  transition: color 0.2s;
}

.icon-list li .icon-label {
  display: none; /* Oculto por padrão */
  font-size: 14px;
  color: var(--icons-sidebar);
  margin-left: 10px;
  white-space: nowrap; /* Evita quebra de linha */
}

.first-sidebar.expanded .icon-list li .icon-label {
  display: inline; /* Exibir ao expandir */
}

.icon-list li:hover {
  background: rgba(255, 255, 255, 0.2); /* Fundo no hover */
  transform: scale(1.1); /* Leve zoom */
}

.icon-list li.active {
  background: none; /* Remove fundo */
  border: none; /* Remove borda */
}

.icon-list li.active::before {
  content: "";
  position: absolute;
  left: -16px; /* Ajuste para encostar na borda preta */
  top: 0;
  bottom: 0;
  width: 5px; /* Largura da barra */
  background: var(--icons-sidebar); /* Cor da barra */
  border-radius: 0 5px 5px 0; /* Arredondamento somente à direita */
  transition: left 0.3s ease; /* Suave transição ao expandir */
}

.first-sidebar.expanded .icon-list li.active::before {
  left: -27px; /* Ajuste ao expandir */
}

.first-sidebar.expanded .theme-switch {
  display: flex;
  justify-content: center;
}

.icon-list li.active i {
  color: var(--icons-sidebar); /* Ícone ativo */
}

/* Botão de alternância da sidebar */
.toggle-button {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 18px;
  color: var(--icons-sidebar);
  transition: transform 0.3s ease;
}

/* Parte inferior da sidebar */
.bottom-section {
  margin-top: auto; /* Move para o final */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espaçamento entre os ícones */
  padding-bottom: 20px; /* Espaçamento inferior */
}

.bottom-section img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 10px;
  border: 2px solid white; /* Destaque ao avatar */
}

/* Ícones de ação */
.action-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.1); /* Fundo transparente */
  transition: all 0.5s ease;
  cursor: pointer;
}

.action-icon:hover {
  background: rgba(255, 255, 255, 0.2); /* Fundo no hover */
  transform: scale(1.1); /* Leve aumento */
}

.action-icon i {
  font-size: 20px;
  color: white;
}

.action-icon:hover i {
  color: #e3f2fd; /* Destaque no hover */
}