.merchant-user-container {
  background-color: var(--card-color);
  border-radius: 20px;
  display: flex;
  width: 100%;
}

.config-title {
  display: flex;
}
.config-title h2 {
  margin: 20px;
}

.config-section {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #c3c3c3;
  gap: 20px;
}

.information-container {
  display: flex;
  align-items: end;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  margin: 10px;
}

.information-content {
  background-color: var(--card-color);
  border-radius: 10px;
  border: solid 1px transparent;
}

.tax-cards-selection {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.tax-card-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  background-color: var(--card-color);
  border-radius: 10px;
}

.personal-information {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.account-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
  color: white;
  box-shadow: var(--box-shadow-color) 0px 4px 12px;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.account-info {
  width: 100%;
  margin: 0 10px;
}

.tab-switch {
  display: flex;
  padding: 20px 0;
  width: 20%;
  border-right: 1px solid #c3c3c3;
}

.select-container {
  width: 75%;
}

.tab-switch button {
  padding: 10px 20px;
  border: none;
  background-color: var(--text-color);
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.tab-switch .active-tab {
  background-color: #00aaff;
}

.perfil-details,
.antecipacao-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input-field {
  margin-bottom: 20px;
}

.input-field label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.profile-picture label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.input-field input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--background-third-color);
  color: white;
}

.profile-picture .upload-box {
  border: 2px dashed #555;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  background-color: var(--background-third-color);
}

.select-option .p-button {
  background: #f5f5f5 !important;
  border: 1px solid #ccc !important;
  color: var(--secondary-text-color) !important;
  box-shadow: none !important;
}

.tab-switch-button {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.disabled-select .p-button {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background: transparent !important;
  color: var(--third-text-color) !important;
  border: 1px solid transparent !important;
  /* border-radius: 12px; */
}

.p-button.p-highlight {
  background: var(--third-text-color) !important;
  color: var(--icons-sidebar) !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.accounts-details,
.information-details,
.tax-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 10px;
}

@media (max-width: 600px) {
  .personal-information {
    flex-wrap: wrap;
  }
}
