.sales-overview-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--card-color);
  border-radius: 24px;
  width: 100%;
  padding: 20px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  flex: 1 1 calc(20% - 20px);
  min-width: 265px;
  max-width: 100%;
  /* box-shadow: var(--box-shadow-color) 0px 4px 12px; */
}

.overview-balance {
  color: var(--text-color);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-title {
  display: flex;
  align-items: center;
  /* flex-direction: column; */
}

.total-sales-header {
  font-size: 22px;
  font-weight: 600;
}

.title {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.07rem;
  color: var(--third-text-color);
}

.icon-card svg {
  width: 50px;
  height: 50px;
  fill: var(--icons-dashboard);
  margin-right: 10px;
}

.dot-button {
  display: flex;
  align-items: center;
}

.dot-button svg {
  width: 30px;
  height: 30px;
  fill: var(--text-color);
  margin-right: 10px;
}

.dot-button svg:hover {
  cursor: pointer;
  border-radius: 50px;
  fill: var(--inverted-text-color);
  background-color: var(--inverted-card-color);
}

.dropdown button {
  font-family: "Poppins", sans-serif;
  background-color: var(--background-color);
  border: none;
  border: solid 1px #c9c9c9;
  border-radius: 8px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.total-sales {
  align-items: baseline;
}

.total-sales h1 {
  font-family: "DM Sans", sans-serif;
  font-size: 36px;
  margin-right: 10px;
}

.total-sales span {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.07rem;
  color: var(--secondary-text-color);
}

.total-sales-info {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.06rem;
  color: var(--text-color);
  margin: 10px 0;
}

.product-selection {
  display: flex;
}

.product-button {
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #666;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-button.selected {
  background-color: #68e0cf;
  color: #fff;
}

.product-button .info-icon {
  margin-left: 5px;
  font-size: 12px;
  color: #fff;
}

.method-selection {
  display: flex;
}

.method-button {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border: none;
  padding: 10px 10px;
  border: 1px solid var(--third-text-color);
  border-radius: 50px;
  font-size: 16px;
  margin-right: 4px;
  cursor: pointer;
  background-color: transparent;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.method-icon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  display: block;
  fill: var(--third-text-color);
}

.method-button.selected {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.692) 70%
  );
  color: #fff;
  font-family: "Poppins", sans-serif;
}
