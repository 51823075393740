/* Estilos para o RetainedCard, prefixado com .retained-card para evitar conflitos */

.retained-card {
  background-color: var(--card-color);
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  position: relative;
  max-width: 100%;
  margin: 5px;
}

.retained-card:hover {
  border: 2px solid var(--inverted-card-color);
}

.retained-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.retained-card-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  color: var(--text-color);
}

.retained-card-icon img.retained-card-icon-image {
  width: 40px; /* Ajuste de tamanho para a imagem PNG */
  height: 40px;
}

.retained-card-description {
  font-family: 'Poppins', sans-serif;
  margin-top: 10px;
  color: var(--text-color);
  font-size: 16px;
}

.retained-card-amount {
  font-size: 24px;
  color: var(--text-color);
  margin-top: 10px;
}

.retained-card-additional {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: var(--secondary-color);
  margin-top: 5px;
}

.method-selection {
  margin-top: 20px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .retained-card {
    max-width: 100%;
    padding: 15px;
  }

  .retained-card-title {
    font-size: 18px;
  }

  .retained-card-amount {
    font-size: 22px;
  }

  .method-button {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .retained-card {
    padding: 10px;
  }

  .retained-card-title {
    font-size: 16px;
  }

  .retained-card-amount {
    font-size: 20px;
  }

  .method-button {
    font-size: 13px;
    padding: 6px;
  }
}
