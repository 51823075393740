.custom-table-wrapper {
  font-family: "Poppins", sans-serif;
  color: var(--text-color);
  background-color: var(--card-color);
  border-radius: 10px; /* Adiciona bordas arredondadas à tabela */
  overflow: hidden; /* Garante que o conteúdo não ultrapasse as bordas */
}

.p-datatable .p-datatable-header,
.p-datatable .p-datatable-thead > tr > th {
  background-color: var(--card-color);
  background: var(--card-color);
  color: var(--text-color);
  border: 1px solid var(--card-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #fff;
}

.p-button.p-button-text {
  background-color: #000;
  color: #fff;
}

.pi-search:before {
  margin-left: 14px;
}

.table-header {
  padding: 1rem;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.table-title {
  font-size: 1.5rem;
  margin: 0;
}

.quick-filters {
  display: flex;
  gap: 0.5rem;
}

.right-filters {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.search-container {
  min-width: 200px;
}

.p-input-icon-left {
  width: 100%;
}

.global-search {
  width: 100%;
  padding-left: 2.5rem;
  background: transparent;
  border: 1px solid black;
  border-radius: 1rem;
}

.p-dropdown {
  min-width: 150px;
}

.p-button.p-button-rounded.p-button-text {
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (max-width: 768px) {
  .header-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .quick-filters,
  .right-filters {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .search-container {
    width: 100%;
  }
}

.p-datatable {
  border-radius: 10px; /* Arredonda as bordas da tabela */
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border: none; /* Remove bordas internas, se necessário */
}

.p-datatable .p-datatable-tbody > tr {
  transition: background-color 0.2s;
  background: var(--card-color);
  color: var(--text-color)
}

.p-datatable .p-datatable-tbody > tr:hover {
  background-color: rgba(0, 0, 0, 0.05); /* Cor de fundo ao passar o mouse */
}

.p-datatable .p-paginator-bottom {
  background-color: transparent;
  border: none;
}
