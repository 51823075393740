.sales-statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--card-color);
  border-radius: 10px;
  margin: 10px;
  padding: 20px;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.statistics-title {
  font-size: 18px;
  margin-bottom: 20px;
  color: var(--text-color);
  text-align: center;
}

.statistics-container {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.sales-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
}

.icon {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.info {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.value {
  font-size: 14px;
  color: var(--text-color);
}

.progress-bar {
  width: 100%;
  background-color: var(--text-color);
  border-radius: 5px;
  height: 5px;
  margin-top: 5px;
}

.progress {
  height: 100%;
  background: linear-gradient(154deg, #68e0cf 0%, #209cff 100%);
  border-radius: 5px;
  transition: width 0.4s ease;
}
