.dashboard-layout {
  display: flex;
  background-color: var(--background-second-color);
}

.dashboard-content {
  display: flex;
}

.content {
  flex-grow: 1; /* O conteúdo vai crescer para ocupar o espaço restante */
  height: 100vh;
  padding: 20px;
  overflow-y: auto; /* Se o conteúdo for longo, permite rolagem vertical */
  transition: margin-left 0.3s ease; /* Anima a mudança no espaço */
}
