.login-page {
  display: flex;
  height: 100vh;
  transition: opacity 0.5s ease-in-out; /* Adiciona a transição */
  opacity: 1; /* Define o estado inicial da opacidade */
}

.login-page.hidden {
  opacity: 0; /* Controla a opacidade quando a transição começa */
}


.login-section {
  flex: 1;
  padding: 5rem 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  text-align: left;
  max-width: 40%;
}

h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: var(--secondary-text-color);
}

.custom-input {
  margin-bottom: 1rem;
  width: 100%;
}

.custom-input input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.remember-me {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #505050;
}

.remember-me input {
  margin-right: 0.5rem;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: #b0b0b0;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--border-color);
  margin: 0 1rem;
}

.divider span {
  color: #707070;
  font-size: 0.9rem;
  font-weight: 500;
}

.error-message {
  color: red;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.register-link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .login-page {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-section {
    max-width: 90%;
    margin: 0 auto;
    padding: 1.5rem;
  }
}
