.kyc-page {
  display: flex;
  flex-direction: column;
}

.steps-container {
  padding: 40px;
  background-color: var(--card-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--box-shadow-color);
  gap: 20px;
}

.input-amount-1,
.step-buttons {
  display: flex;
  /* flex-wrap: wrap; */
}

.tax-cards-selection {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.tax-card-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tax-percent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border: solid 1px #c4c4c4;
  border-radius: 4px;
}

.upload-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  background-color: var(--card-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--box-shadow-color);
}

.upload-step-container h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.upload-step-container p {
  font-size: 16px;
  margin-bottom: 30px;
}

.upload-field {
  padding: 5px;
  width: 80%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #cdcdcd;
  border-radius: 10px;
}

.upload-field label {
  font-size: 18px;
  margin-bottom: 8px;
}

.upload-field input[type="file"] {
  font-size: 16px;
  margin-bottom: 10px;
}

.preview-image {
  width: 150px;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.step-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.label-content {
  display: flex;
  flex-direction: column;
}

.login-button {
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.bank-suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  z-index: 1000;
  background-color: var(--background-color);
}

.bank-suggestions li {
  padding: 10px;
  cursor: pointer;
}

.bank-suggestions li:hover {
  background-color: #f0f0f0;
}

.image-content {
  display: flex;
  justify-content: center;
  width: 100%;
  border: solid 1px #cdcdcd;
  border-radius: 4px;
}

.label-content label {
  font-weight: bold; /* Negrito */
  font-size: 18px; /* Tamanho da fonte */
  color: var(--text-color); /* Cor do texto */
  margin-bottom: 8px; /* Espaçamento abaixo do label */
}

.final-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--box-shadow-color);
}

.final-step-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.final-step-container p {
  font-size: 16px;
  margin-bottom: 20px;
}

.final-step-container .login-button {
  padding: 12px 20px;
  font-size: 18px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.final-step-container .login-button:hover {
  background-color: #218838;
}

@media (max-width: 600px) {
  .input-amount-1,
  .step-buttons {
    flex-wrap: wrap;
  }
}
