.status-label {
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;
}
