.account-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 8px;
  color: white;
  box-shadow: var(--box-shadow-color) 0px 4px 12px;
}

.account-info {
  width: 100%;
  margin: 0 10px;
}

.tab-switch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tab-switch button {
  padding: 10px 20px;
  border: none;
  background-color: var(--text-color);
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.tab-switch .active-tab {
  background-color: #00aaff;
}

.perfil-details,
.antecipacao-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.input-field {
  margin-bottom: 20px;
}

.input-field label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.profile-picture label {
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.api-content {
  background-color: var(--card-color);
  border-radius: 8px;
}

.input-field input {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--background-third-color);
  color: white;
}

.save-button {
  font-weight: 600;
  width: 40%;
}

.profile-picture .upload-box {
  border: 2px dashed #555;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  background-color: var(--background-third-color);
}

/* Sobrescrevendo o estilo p-button para SelectButton */
.select-option .p-button {
  background: #f5f5f5 !important;
  border: 1px solid #ccc !important;
  color: var(--secondary-text-color) !important;
  box-shadow: none !important;
}
